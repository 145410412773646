<template>
	<div id="weixin-pay">
		<Header2022 :navbg='true'></Header2022>
		<div class="order">
			订单提交成功，请尽快付款！订单号：{{orderId}}
		</div>
		<div class="code">
			<div class="refresh" @click="refreshTap">
				<i>请尽快扫码支付，如遇问题可</i>
				<i class="blue">刷新</i>
				<i>页面重新获取</i>
			</div>
			<!-- <div id="qrcode"></div> -->
			<div id="qrcodes" v-if="qrCodeText">
				<qrcodes :url="qrCodeText"
					iconurl="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/icon/qiandao.svg"
					:wid="300" :hei="300" :imgwid="40" :imghei="40" @change="onDataUrlChange" />
			</div>
			<div class="tips">请使用微信扫一扫</div>
			<div class="tips">扫描二维码支付</div>
		</div>
		<div class="foot">
			<Footer2022></Footer2022>
		</div>
	</div>
</template>
<script>
	history.pushState(null, null, document.URL);
	window.addEventListener('popstate', function() {
		history.pushState(null, null, document.URL);
	});
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import qrcodes from "vue_qrcodes"
	import utils from '@/common/utils.js'
	import {
		mapState
	} from 'vuex'

	export default {
		components: {
			Header2022,
			Footer2022,
			qrcodes
		},
		data() {
			return {
				appCount: null,
				orderId: '',
				qrCodeText: '',
			}
		},
		computed: {
			...mapState(['houseApi', 'user_info']),
		},
		activated() {
			this.orderId = this.$route.params.id;
			this.onDataUrlChange(unescape(this.$route.params.code));
		},
		destroyed() {
			clearInterval(this.appCount);
			this.appCount = null;
		},
		methods: {
			onDataUrlChange(dataUrl) {
				this.qrCodeText = dataUrl;
				this.$store.commit('emptyHouse');
				this.queryPay();
			},
			queryPay() {
				this.$axios.post(this.houseApi + `pc/house/orders/pay/query/${this.$route.params.id}`, {
						id: this.$route.params.id
					})
					.then(res => {
						//1是成功  0未支付
						if (!res.data.status || res.data.status > 0) {
							clearInterval(this.appCount);
							this.appCount = null;
						}
						if (res.data.status == 1) {
							this.$message.success(res.data.message);
							this.$router.replace({
								name: 'appointment',
								params: {
									tagName:'order',
								}
							});
							return;
						}
						if (res.data.status == 0) {
							this.polling();
						}
					})
					.catch(error => {
						clearInterval(this.appCount);
						this.appCount = null;
					});
			},
			polling() {
				var that = this;
				that.appCount = setInterval(function() {
					that.queryPay();
				}, 9000);
			},
			refreshTap: utils.debounce(
				function() {
					this.continuePay();
				},
				500,
				true
			),
			continuePay() {
				this.$axios.post(this.houseApi + `pc/house/orders/${this.$route.params.id}/pay`, {
						telephone: this.user_info.telephone,
						id: this.$route.params.id
					})
					.then(res => {
						if (res.data.status == 1) {
							var code = res.data.data.paycode.payment_params.code_url;
							this.onDataUrlChange(code);
						} else {
							this.$message.error(res.data.message);
						}
					})
					.catch(error => {
						this.$message.error(error.message);
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	#weixin-pay {
		padding-top: 104px;
		background-color: #f7f7f7;
		height: 98vh;
		box-sizing: border-box;

		.order {
			font-size: 20px;
			padding-left: 70px;
		}

		.code {
			display: flex;
			align-items: center;
			flex-direction: column;
			position: relative;
			margin-bottom: 30px;

			#qrcodes {
				margin-bottom: 40px;
			}

			.tips {
				width: 300px;
				color: #FFFFFF;
				background-color: #ff7674;
				font-size: 14px;
				line-height: 18px;
				font-weight: 700;
				padding: 10px 0;
				text-align: center;
			}
		}

		.refresh {
			margin-top: 20px;
			font-size: 20px;
			color: #999999;
			margin-bottom: 20px;

			.blue {
				margin-left: 4px;
				color: #03a3ff;
			}
		}

		.foot {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}
</style>
